
.wrapper{
    max-width: 314px;
    margin: auto;
    padding: 5px;
    height: 45px;
    background: #ECEEF2;
    border-radius: 22px;
    margin-bottom: 22px;
}
.wrapper nav{
  position: relative;
  display: flex;
  height: 35px;
}
.wrapper nav label{
  flex: 1;
  width: 100%;
  z-index: 1;
  cursor: pointer;
  align-self: center;
  text-align: center;

  position: relative;
  color: #1d1f20;
  text-decoration: none;
  transition: color 0.6s ease;

  font-weight: 600;
  font-size: 14px;
  line-height: 139.3%;
  letter-spacing: -0.035em;
  text-transform: capitalize;

}
.wrapper nav #home:checked ~ label.home ,
.wrapper nav #inbox:checked ~ label.inbox  {
  color: #fff;
}

.wrapper nav .tab{
  position: absolute;
  height: 35px;
  width: 50%;
  left: 0;
  bottom: 0;
  z-index: 0;
  background: #0A7EF9;
    border-radius: 17.5px;
  transition: 0.6s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}
.wrapper nav #inbox:checked ~ .tab{
  left: 50%;
}
.wrapper nav input{
  display: none;
}
